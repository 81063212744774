import * as React from "react";

const Robot = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 39">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Robot_icons" data-name="Robot icons">
        <circle className="cls-1" cx="26.5" cy="2" r="2" />
        <line className="cls-2" x1="19" y1="32" x2="29" y2="32" />
        <polyline className="cls-2" points="24 14 27 9 23 7 26 3" />
        <path
          className="cls-2"
          d="M10,21V34a4,4,0,0,0,4,4H34a4,4,0,0,0,4-4V18a4,4,0,0,0-4-4H14a4,4,0,0,0-4,4Z"
        />
        <polyline className="cls-2" points="10 21 5 22 5 30 10 31" />
        <polyline className="cls-2" points="38 21 43 22 43 30 38 31" />
        <rect className="cls-2" x="15" y="21" width="6" height="5" />
        <rect className="cls-2" x="27" y="21" width="6" height="5" />
        <line className="cls-2" x1="5" y1="26" x2="1" y2="26" />
        <line className="cls-2" x1="43" y1="26" x2="47" y2="26" />
      </g>
    </g>
  </svg>
);

export default Robot;
