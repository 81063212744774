import * as React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { WmkSeo } from "wmk-seo";
import Robot from "../components/common/Robot";
import Layout from "../components/layout/MainLayout";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import styled from "styled-components";
import { colors } from "../vars/palette";

const StyledCon = styled(Container)`
  svg {
    fill: ${colors.hex("text")};
  }
  color: ${colors.hex("text")};
  font-family: Rajdhani, sans-serif;
  p {
    font-family: "Nunito Sans", sans-serif;
  }
`;

const NotFoundPage = () => (
  <Layout showHeadFoot>
    <WmkSeo.Meta
      title={`404 Page Not Found`}
      path={"/"}
      slug={"404"}
      ogImageUrl={null}
      twitterImageUrl={null}
      twitterHandle={twitter}
      siteTitle={siteTitle}
      baseUrl={baseUrl}
    />
    <StyledCon>
      <Row style={{ padding: `2rem` }}>
        <Col xs={12}>
          <Row
            style={{
              width: `100%`,
              display: "flex",
              justifyContent: "center"
            }}>
            <Col xs={6}>
              <Robot />
            </Col>
          </Row>
        </Col>
        <Col style={{ textAlign: "center" }}>
          <h1>NOT FOUND</h1>
          <p>This is not the page you're looking for.</p>
        </Col>
      </Row>
    </StyledCon>
  </Layout>
);

export default NotFoundPage;
